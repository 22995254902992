<template>
  <div class="slider">
    <div class="mb-4">
      <v-card class="pa-6 mx-0">
        <v-row class="justify-space-between align-center">
          <v-col
            md="4"
            xl="4"
            cols="12"
            sm="12"
          >
            <h2>Slider</h2>
            <small style="white-space: normal;">Halaman untuk menambahkan slider ke aplikasi</small>
          </v-col>
        </v-row>
      </v-card>
    </div>

    <div>
      <v-row
        v-if="sliders.length || !isLoadingData"
        class="align-center"
      >
        <v-col

          md="4"
          cols="12"
        >
          <a
            class="text-decoration-none"
            @click.prevent="showFormAdd()"
          >
            <v-card
              height="330"
              color="#F5F5F5"
              style="border: 1px dashed #c4c4c4;"
              class="d-flex align-center justify-center"
            >
              <div class="text-center">
                <v-icon size="40">
                  {{ icons.mdiPlus }}
                </v-icon>
                <p>Tambah Slider</p>
              </div>
            </v-card>
          </a>
        </v-col>

        <v-col
          v-for="(sliderItem,index) in sliders"
          :key="index"
          md="4"
          cols="12"
        >
          <v-card
            min-height="307"
          >
            <v-card
              class="overflow-hidden"
              style="z-index: 0"
            >
              <v-card-title
                class="pa-2 position-absolute"
                style="right: 0; z-index: 1;"
              >
                <v-spacer></v-spacer>
                <v-menu
                  offset-y
                  nudge-bottom="5"
                  nudge-left="60"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="#b6b6b6"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="20">
                        {{ icons.mdiDotsVertical }}
                      </v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <a @click.prevent="showFormEdit(sliderItem.uuid)">
                      <v-list-item>
                        <v-list-item-title>
                          <div class="d-flex">
                            <div>
                              <v-img
                                width="16"
                                src="@/assets/icons/edit.svg"
                              ></v-img>
                            </div>

                            <small class="ml-2">Edit</small>
                          </div>
                        </v-list-item-title>
                      </v-list-item>
                    </a>

                    <a @click.prevent="confirmDestroy(sliderItem.uuid, sliderItem.title)">
                      <v-list-item>
                        <v-list-item-title>
                          <div class="d-flex btn-delete">
                            <div>
                              <v-img
                                width="16"
                                src="@/assets/icons/trash-red.svg"
                              ></v-img>
                            </div>

                            <small class="ml-2 text-delete">Hapus</small>
                          </div>
                        </v-list-item-title>
                      </v-list-item>
                    </a>
                  </v-list>
                </v-menu>
              </v-card-title>

              <div
                style="z-index: 0;"
              >
                <v-img
                  :src="sliderItem.image"
                  height="150"
                ></v-img>
              </div>
            </v-card>

            <v-card-text class="py-4">
              <div class="mb-4">
                <h2 class="font-weight-bold text-truncate mb-2">
                  {{ sliderItem.title }}
                </h2>
                <p class="font-weight-light text-truncate mb-2">
                  {{ sliderItem.description }}
                </p>
              </div>

              <div class="d-flex">
                <div class="me-2 mb-2">
                  <v-img
                    src="@/assets/icons/calendar.svg"
                    sizes="20"
                  ></v-img>
                </div>
                <span class="font-weight-light">{{ sliderItem.start_date }} - {{ sliderItem.start_date }} </span>
              </div>

              <div class="d-flex">
                <div class="me-2">
                  <v-img
                    src="@/assets/icons/clip.svg"
                    sizes="20"
                  ></v-img>
                </div>
                <span class="font-weight-light text-truncate mb-2">{{ sliderItem.url }}</span>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          md="1"
          cols="12"
        >
          <infinite-loading
            slot="append"
            spinner="spiral"
            :identifier="infiniteId"
            @infinite="infiniteHandler"
            @distance="1"
          >
            <div slot="no-more">
            </div>
            <div slot="no-results">
            </div>
          </infinite-loading>
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col
          v-for="i in 3"
          :key="i"
          md="4"
        >
          <v-skeleton-loader
            type="image"
            :loading="true"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>

    <ModalDialog
      :visible="modalDialog"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="dialog === 'add' ? !formValid || slider.image === null || error || !isValid || !imageCropped : !formValid || slider.image === null"
      :width="1000"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah Slider': 'Edit Slider'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template v-slot:body>
        <div>
          <FileInput
            v-model="initialImage"
            label="Gambar"
            outlined
            dense
            :loading-image="loadingImage"
            :disabled="isLoadingData"
            :prependicon="icons.mdiImageMultipleOutline"
            @input="getFile"
          >
          </FileInput>
        </div>

        <div v-show="showCropper">
          <v-row>
            <v-col cols="12">
              <section class="cropper-area mb-6">
                <div
                  v-if="initialImage == null"
                  class="text-decoration-none"
                >
                  <v-card
                    height="280"
                    color="#efefef"
                    outlined
                    class="d-flex align-center justify-center rounded-lg"
                  >
                    <div class="text-center">
                      <v-img
                        src="@/assets/icons/black/gallery-add.svg"
                        width="70"
                        class="mx-auto"
                      ></v-img>
                      <h3 clas="ma-0 text--success">
                        Tambah Slider
                      </h3>
                    </div>
                  </v-card>
                </div>

                <div
                  v-else
                  class="d-flex justify-start flex-column"
                >
                  <ModalDialog
                    :visible="showCropModal"
                    :is-loading-button="isLoadingButton"
                    :width="1000"
                    text-button="Edit"
                    header="Potong Gambar"
                    :hidden-actions="false"
                    @close-modal="closeCropModal"
                  >
                    <template v-slot:body>
                      <div>
                        <vue-cropper
                          ref="cropper"
                          :aspect-ratio="2.91 / 1"
                          :src="imgSrc"
                          preview=".preview"
                          :auto-crop-area="1"
                          drag-mode="crop"
                          :view-mode="3"
                          :min-container-width="0"
                          :min-container-height="0"
                          :crop-box-resizable="false"
                          :img-style="{ height: '325px' }"
                        />
                      </div>

                      <div class="actions text-center py-2">
                        <v-btn
                          color="primary"
                          outlined
                          small
                          class="mr-3 mt-3 btn-tools"
                          @mousedown="move(0, 15)"
                          @mouseup="stopMove"
                        >
                          <v-icon>{{ icons.mdiArrowUpThin }}</v-icon>
                        </v-btn>
                        <v-btn
                          color="primary"
                          outlined
                          small
                          class="mr-3 mt-3 btn-tools"
                          @mousedown="move(0, -15)"
                          @mouseup="stopMove"
                        >
                          <v-icon>{{ icons.mdiArrowDownThin }}</v-icon>
                        </v-btn>
                        <v-btn
                          color="primary"
                          outlined
                          small
                          class="mr-3 mt-3 btn-reset"
                          @click="reset"
                        >
                          <v-icon left>
                            {{ icons.mdiRefresh }}
                          </v-icon>
                          Reset
                        </v-btn>

                        <v-btn
                          color="primary"
                          small
                          class="mt-3 btn-crop"
                          @click="cropImage"
                        >
                          <v-icon left>
                            {{ icons.mdiCrop }}
                          </v-icon>
                          Crop
                        </v-btn>
                      </div>
                    </template>
                  </ModalDialog>
                </div>
              </section>
            </v-col>
            <div v-if="slider.image">
              <v-col
                cols="12"
                class="pt-0 mt-n11"
              >
                <section class="preview-area mb-6">
                  <p class="mt-0 mb-3">
                    Preview
                  </p>
                  <div class="cropped-image">
                    <img
                      v-if="slider.image"
                      :src="imageUrl"
                      alt="Slider Image"
                      class="rounded-lg contain-image"
                    />
                    <div
                      v-else
                      class="crop-placeholder rounded-lg"
                    />

                    <div class="actions">
                      <v-btn
                        color="primary"
                        outlined
                        small
                        class="mr-3 mt-1 btn-crop btn-reset"
                        @click="deleteImage"
                      >
                        <v-icon left>
                          {{ icons.mdiTrashCanOutline }}
                        </v-icon>
                        Hapus
                      </v-btn>
                      <v-btn
                        color="primary"
                        small
                        class="mt-1 btn-crop"
                        @click="showCropModal = true"
                      >
                        <v-icon>{{ icons.mdiPencil }}</v-icon>
                        Edit
                      </v-btn>
                    </div>
                  </div>
                </section>
              </v-col>
            </div>
          </v-row>
        </div>

        <div class="my-3">
          <p class="mb-2 text-subtitle-1 font-weight-bold">
            Aksi ketika slider di klik:
          </p>
          <div class="d-flex flex-wrap mb-6">
            <span class="mr-5">
              <input
                id="detailPopUp"
                v-model="slider.is_popup"
                type="radio"
                :value="1"
                name="popupOption"
                class="mr-2"
              >
              <label for="detailPopUp">Tampilkan detail di dalam aplikasi</label>
            </span>
            <span>
              <input
                id="openLink"
                v-model="slider.is_popup"
                type="radio"
                :value="0"
                name="popupOption"
                class="mr-2"
              >
              <label for="openLink">Buka link url di browser</label>
            </span>
          </div>
        </div>

        <div>
          <v-text-field
            v-model="slider.title"
            label="Judul"
            outlined
            dense
            :loading="isLoadingData"
            :disabled="isLoadingData"
          ></v-text-field>
        </div>

        <div>
          <v-menu
            v-model="start_date"
            :close-on-content-click="true"
            nudge-right="0"
            nudge-top="20"
            max-width="100%"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="slider.start_date"
                label="Waktu Mulai"
                readonly
                outlined
                dense
                v-bind="attrs"
                :loading="isLoadingData"
                :disabled="isLoadingData"
                v-on="on"
                @click="autofillStartDate"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="slider.start_date"

              :first-day-of-week="0"
              locale="id-id"
              :min="new Date().toISOString().substr(0, 10)"
              color="primary"
            ></v-date-picker>
          </v-menu>
        </div>

        <div>
          <v-menu
            v-model="end_date"
            :close-on-content-click="true"
            nudge-right="0"
            nudge-top="20"
            max-width="100%"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="slider.end_date"
                label="Waktu Selesai"
                outlined
                dense
                v-bind="attrs"
                :loading="isLoadingData"
                :disabled="isLoadingData"
                v-on="on"
                @click="autofillEndDate"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="slider.end_date"

              :first-day-of-week="0"
              locale="id-id"
              color="primary"
              :min="new Date().toISOString().substr(0, 10)"
            ></v-date-picker>
          </v-menu>
        </div>

        <div
          class="mb-1"
        >
          <p class="mb-1 text-caption text-left text-sm-right">
            Pastikan Link URL diawali dengan http:// atau https://
          </p>
          <v-text-field
            v-model="slider.url"
            type="url"
            label="Link Slider"
            outlined
            dense
            :loading="isLoadingData"
            :disabled="isLoadingData"
            :hint="invalidMessage"
            :error="errorURL"
            @input="checkURL($event)"
            @change="checkURL($event)"
          ></v-text-field>
        </div>

        <div v-if="slider.is_popup">
          <v-textarea
            v-model="slider.description"
            label="Deskripsi"
            outlined
            dense
            :loading="isLoadingData"
            :disabled="isLoadingData"
          ></v-textarea>
        </div>

        <div>
          <v-text-field
            v-model="slider.order"
            label="Urutan"
            outlined
            dense
            type="number"
            :hint="messageOrder"
            persistent-hint
            :error="error"
            :loading="isLoadingData"
            :disabled="isLoadingData"
            @keyup="checkOrder"
          ></v-text-field>
        </div>
      </template>
    </ModalDialog>

    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Slider"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    />
  </div>
</template>

<script>
import dateTimeFormat from '@/utils/date/dateTimeFormat'
// eslint-disable-next-line object-curly-newline
import { mdiArrowDownThin, mdiArrowUpThin, mdiCrop, mdiDotsVertical, mdiImageMultipleOutline, mdiInformation, mdiPencil, mdiPlus, mdiRefresh, mdiTrashCanOutline } from '@mdi/js'
import 'cropperjs/dist/cropper.css'
import VueCropper from 'vue-cropperjs'
import InfiniteLoading from 'vue-infinite-loading'
import ConfirmDialog from '../components/ConfirmDialog.vue'
import FileInput from '../components/FileInput.vue'
import ModalDialog from '../components/ModalDialog.vue'

export default {
  name: 'Slider',
  components: {
    VueCropper,
    ModalDialog,
    ConfirmDialog,
    FileInput,
    InfiniteLoading,
  },
  data() {
    return {
      icons: {
        mdiPlus,
        mdiImageMultipleOutline,
        mdiDotsVertical,
        mdiInformation,
        mdiRefresh,
        mdiCrop,
        mdiArrowUpThin,
        mdiArrowDownThin,
        mdiPencil,
        mdiTrashCanOutline,
      },
      imgSrc: '',
      infiniteId: +new Date(),
      error: false,
      errorURL: false,
      imageCropped: false,
      end_date: '',
      start_date: '',
      isLoadingCard: true,
      isLoadingButton: false,
      loadingImage: false,
      modalDialog: false,
      dialog: '',
      search: '',
      confirmDialog: false,
      page: 1,
      isLoadingData: true,
      totalItemsLib: 0,
      totalPagesLib: 100,
      showCropper: false,
      pageLib: 1,
      sliders: [],
      slider: {
        uuid: null,
        title: '',
        order: null,
        image: null,
        start_date: '',
        end_date: '',
        url: '',
        is_popup: 0,
        description: '',
      },
      isValid: false,
      invalidMessage: '',
      messageOrder: '',
      serviceSlider: 'slider',
      formValid: false,
      moveInterval: null,
      showCropModal: true,
      cropped: false,
    }
  },
  computed: {
    initialImage: {
      get() {
        if (typeof this.slider.image === 'string') {
          return new File([this.slider.image], this.slider.image, { type: 'text/plain' })
        }

        return this.slider.image
      },
      set() {
        return this.slider.image
      },
    },
    imageUrl() {
      if (this.slider.image) {
        return URL.createObjectURL(this.slider.image)
      }

      return null
    },
  },
  watch: {
    slider: {
      handler() {
        const valid = []
        let requiredField = []
        if (this.dialog === 'edit') {
          requiredField = ['title', 'start_date', 'end_date']
        } else {
          requiredField = ['title', 'order', 'start_date', 'end_date']
        }

        if (this.slider.is_popup) {
          requiredField.push('description')
        }

        Object.entries(this.slider).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
    page: {
      handler() {
        this.listSlider()
      },
    },
    // eslint-disable-next-line func-names
    'slider.is_popup': function () {
      if (!this.slider.is_popup) {
        this.slider.description = ''
        this.slider.url = ''
      }
    },
  },
  mounted() {
    this.checkURL()
  },
  beforeDestroy() {
    // Membersihkan URL objek ketika komponen dihancurkan untuk mencegah memory leak
    if (this.slider.image) {
      URL.revokeObjectURL(this.imageUrl)
    }
  },
  created() {
    this.listSlider()
  },
  methods: {
    async infiniteHandler($state) {
      this.pageLib += 1

      await this.$services.ApiServices.list(this.serviceSlider, {
        page: this.pageLib,
        per_page: 5,
        is_admin: 1,
      }).then(({ data }) => {
        if (data.data.length) {
          this.sliders = [...this.sliders, ...data.data]
          this.isLoadingCard = false
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    },

    async listSlider(params = {}) {
      this.isLoadingData = true

      await this.$services.ApiServices.list(this.serviceSlider, {
        ...params,
        per_page: 5,
        is_admin: 1,
      }).then(
        ({ data }) => {
          this.sliders = data.data
        },
        err => {
          console.error(err)
          this.isLoadingData = false
        },
      )
      this.isLoadingData = false
    },

    checkURL(event) {
      const url = event
      this.isURLValid(url)
    },

    isURLValid(inputUrl) {
      const regex = /^(http|https):\/\/+[\www\d]+\.[\w]+(\/[\w\d]+)?/
      if (this.slider.is_popup) {
        this.isValid = regex.test(inputUrl) || inputUrl === ''
      } else {
        this.isValid = regex.test(inputUrl)
      }

      if (this.isValid === false) {
        this.invalidMessage = 'URL is invalid'
        this.errorURL = true
      } else {
        this.invalidMessage = ''
        this.errorURL = false
      }
    },

    move(offsetX, offsetY) {
      this.moveInterval = setInterval(() => {
        this.$refs.cropper.move(offsetX, offsetY)
      }, 20)
    },

    stopMove() {
      clearInterval(this.moveInterval)
      this.$refs.cropper.getCroppedCanvas().toBlob(blob => {
        this.slider.image = blob
      }, this.mime_type)
    },

    cropImage() {
      this.imageCropped = true
      this.$refs.cropper.getCroppedCanvas().toBlob(blob => {
        blob.name = this.slider.image.name
        this.slider.image = blob
        this.showSnackbar({
          text: 'Berhasil crop gambar',
          color: 'success',
        })
        this.showCropModal = false
        this.cropped = true
      }, this.mime_type)
    },

    closeCropModal() {
      this.showCropModal = false
      if (this.cropped === false) {
        this.slider.image = null
      }
    },

    deleteImage() {
      this.imageCropped = false
      this.slider.image = null
      this.cropped = false
    },

    reset() {
      this.$refs.cropper.reset()
      this.imageCropped = false
    },

    getFile(event) {
      this.slider.image = event
      this.imageCropped = false

      const file = event

      if (file.type.indexOf('image/') === -1) {
        this.showSnackbar({
          text: 'Silahkan tambahkan satu file gambar',
          color: 'error',
        })

        return
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = event => {
          this.imgSrc = event.target.result
          this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(file)
      } else {
        this.showSnackbar({
          text: 'Maaf, FileReader API tidak didukung',
          color: 'error',
        })
      }

      this.showCropper = true
      this.showCropModal = true
    },

    async add() {
      this.isLoadingButton = true

      this.formData = new FormData()
      this.formData.append('image', this.slider.image)
      this.formData.append('title', this.slider.title)
      this.formData.append('order', this.slider.order)
      this.formData.append('url', this.slider.url)
      this.formData.append('description', this.slider.description)
      this.formData.append('start_date', this.slider.start_date)
      this.formData.append('end_date', this.slider.end_date)
      this.formData.append('is_popup', this.slider.is_popup ? 1 : 0)
      this.formData.append('is_admin', 1)

      await this.$services.ApiServices.add(this.serviceSlider, this.formData).then(
        ({ data }) => {
          this.showSnackbar({
            text: 'Add Slider has Successfully',
            color: 'success',
          })
          this.refreshInfiniteLoading()
          this.isLoadingButton = false
        },
        err => {
          console.error(err)
          this.isLoadingButton = false
        },
      )

      this.isLoadingButton = false
      this.modalDialog = false
      this.resetForm()
      this.listSlider()
    },

    async update() {
      this.isLoadingButton = true

      this.formData = new FormData()
      this.formData.append('image', this.slider.image)
      this.formData.append('title', this.slider.title)
      this.formData.append('order', this.slider.order)
      this.formData.append('url', this.slider.url)
      this.formData.append('description', this.slider.description)
      this.formData.append('start_date', this.slider.start_date)
      this.formData.append('end_date', this.slider.end_date)
      this.formData.append('is_popup', this.slider.is_popup ? 1 : 0)
      if (typeof this.slider.image === 'string') {
        this.formData.delete('image')
      }

      await this.$services.ApiServices.update(this.serviceSlider, this.formData, this.slider.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.refreshInfiniteLoading()
          this.isLoadingButton = false
        },
        err => {
          console.error(err)
          this.isLoadingButton = false
        },
      )

      this.isLoadingButton = false
      this.modalDialog = false
      this.resetForm()
      this.listSlider()
    },

    async checkOrder() {
      this.isLoadingOrder = true
      if (this.slider.order.length) {
        await this.$services.ApiServices.checkOrder(this.serviceSlider, { order: this.slider.order }).then(
          ({ data }) => {
            this.error = false
          },
          err => {
            this.error = true
            this.messageOrder = 'The order slider already exists'
            this.isLoadingOrder = false
          },
        )

        this.isLoadingOrder = false
      }
    },

    async destroy() {
      this.isLoadingButton = true

      await this.$services.ApiServices.destroy(this.serviceSlider, this.slider.uuid).then(({ data }) => {
        this.showSnackbar({
          text: 'Slider has successfully deleted !',
          color: 'error',
        })
        this.refreshInfiniteLoading()
      })

      this.isLoadingButton = false
      this.confirmDialog = false
      this.listSlider()
    },

    refreshInfiniteLoading() {
      if (this.sliders.length > 3) {
        this.pageLib = 1
        this.infiniteId += 1
      }
    },

    confirmDestroy(uuid, title) {
      this.confirmDialog = true
      this.slider.title = title
      this.slider.uuid = uuid
    },

    showFormAdd() {
      this.invalidMessage = ''
      this.errorURL = false
      this.resetForm()
      this.dialog = 'add'
      this.messageOrder = ''
      this.error = false
      this.isLoadingData = false
      this.loadingImage = false
      this.modalDialog = true
    },

    async showFormEdit(uuid) {
      this.dialog = 'edit'
      this.modalDialog = true
      this.invalidMessage = ''
      this.errorURL = false
      this.imageCropped = false
      this.showCropper = false
      this.loadingImage = true
      this.isLoadingData = true

      await this.$services.ApiServices.get(this.serviceSlider, uuid).then(
        ({ data }) => {
          this.slider = data.data
          this.slider.is_popup = data.data.is_popup ? 1 : 0
        },
        err => {
          console.log(err)
        },
      )
      this.isLoadingData = false
      this.loadingImage = false
    },

    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },

    autofillStartDate() {
      this.slider.start_date = new Date().toISOString().substr(0, 10)
    },

    autofillEndDate() {
      this.slider.end_date = new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString().substr(0, 10)
    },

    resetForm() {
      this.slider.uuid = null
      this.slider.image = null
      this.slider.start_date = ''
      this.slider.end_date = ''
      this.slider.url = ''
      this.slider.title = ''
      this.slider.description = ''
      this.slider.is_popup = 0
      this.imgSrc = ''
      this.slider.order = null
      this.imageCropped = false
      this.showCropper = false
    },

    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },

    dateFormat(date) {
      dateTimeFormat(date, '-', false, false)
    },
  },
}
</script>

<style>
.alert > .v-alert__wrapper > .v-alert__content {
  display: flex;
  align-items: center;
}
.text-delete {
  color: #e30000 !important;
}
.theme--light.v-skeleton-loader .v-skeleton-loader__image {
  height: 307px;
}
.loading-spiral {
  border: 3px solid #777 !important;
  border-right-color: transparent !important;
  -webkit-animation: loading-rotating-data-v-46b20d22 linear .85s infinite !important;
  animation: loading-rotating-data-v-46b20d22 linear .85s infinite !important;
}

.btn-reset,
.btn-crop {
  width: 155px;
}

.btn-tools {
  height: 30px !important;
  width: 30px !important;
  border-radius: 50% !important;
  min-width: 0 !important;
  padding: 0 !important;
}

.contain-image {
  width: 100%;
  object-fit: contain;
}
</style>
